import { graphql } from 'gatsby';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Layout from '../../layouts/Layout';
import PostCards from '../../components/PostCards';
import Seo from '../../components/Seo';
import Cta from '../../partials/Cta';
import Features from '../../partials/Features';
import IntegrationGraphic from '../../partials/IntegrationGraphic';

import ZapierLogo from '../../assets/images/zapier.png';

const Zapier = (props) => {
  const { t } = useTranslation();

  const { articles, posts } = props.data;
  const content = _sortBy(articles.nodes.concat(posts.nodes), [
    (c) => {
      return c.publishDate ?? c.createdAt;
    },
  ]);

  return (
    <Layout showHeader>
      <Seo title="Zapier" path="/integrations/zapier" />
      <div className="px-4 sm:px-6 lg:px-16 mx-auto max-w-5xl prose">
        <article>
          <div className="mb-8 text-center uppercase">
            <span className="py-2 px-4 mr-2 text-xs text-white no-underline bg-oxford-blue rounded-full">
              {t('Integrations')}
            </span>
          </div>
          <h1 className="text-center">
            Publish the copy you create in Copybara with your Zapier zaps
          </h1>
          <h2 className="mt-0 mb-8 text-xl font-normal text-center text-gray-500">
            Use Zapier to connect your Copybara copy
            <br />
            to more complex communication workflows
          </h2>
          <IntegrationGraphic
            service={{ icon: ZapierLogo, text: t('Zapier Logo') }}
          />
        </article>
      </div>
      <Cta
        wrapperClasses="relative py-12 md:py-20 px-4 sm:px-6 text-center border-t border-b"
        heading={`Craft your copy in Copybara and\nintegrate it with Zapier`}
        buttonText="Get started for free"
        subheading={null}
      />
      <div className="px-4 sm:px-6 lg:px-16 my-12 md:my-16 mx-auto max-w-5xl">
        <h2 className="my-8 text-3xl md:text-4xl text-center">
          {t('Guides for Copybara + Zapier')}
        </h2>
        <PostCards posts={content} />
      </div>
      <Features />
      <Cta />
    </Layout>
  );
};

export default Zapier;

export const query = graphql`
  query ZapierData {
    articles: allContentfulKbAppArticle(
      sort: { fields: createdAt, order: DESC }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: "zapier" } } } }
      }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
        createdAt
        metaDescription
        category: kbAppCategory {
          slug
          name
        }
      }
    }
    posts: allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: "zapier" } } } }
      }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
        publishDate
        excerpt {
          internal {
            content
          }
        }
      }
    }
  }
`;
